import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './LoanDetailPageView.scss';
import {images} from "@assets/images";

interface LoanItem {
    id: number;
    name: string;
    iconUrl: string;
    loanLimit: number;
    term: number;
    interestRate: number;
    benefits: number;
}

const LoanDetailPageView: React.FC = () => {
    const history = useHistory();

    const location = useLocation<{ loanItem: LoanItem }>();

    const loanItem = location.state?.loanItem;

    if (!loanItem) {
        return <p>Không có thông tin khoản vay.</p>;
    }

    const handleCreateLoan = (loanItem: LoanItem) => {
        history.push('/register-loan', { loanItem });
    };

    return (
        <div className="container">
            <div className="banner-container">
                <img className='banner-tnex' src={images.tnex.tnexBanner} alt="Banner TNEX"/>
            </div>
            <p className="loan-detail-header">Chi tiết sản phẩm vay</p>
            <div className="loan-detail">
                <div className="header">
                    <img src={loanItem.iconUrl} alt="loan-img" className="logo-loan-detail"
                         style={{width: '144px', height: '50px'}}/>
                    <h2 className="title-loan-detail">{loanItem.name}</h2>
                    <button className="button-loan-detail" onClick={() => handleCreateLoan(loanItem)}>Đăng ký ngay
                    </button>
                </div>
                <div className="infoRow-loan-detail">
                    <span className="label-loan-detail">Hạn mức khoản vay:</span>
                    <span className="value-loan-detail">3 triệu - {loanItem.loanLimit} triệu</span>
                </div>
                <div className="infoRow-loan-detail">
                    <span className="label-loan-detail">Lãi suất:</span>
                    <span className="value-loan-detail">Từ {loanItem.interestRate}%/năm</span>
                </div>
                <div className="infoRow-loan-detail">
                    <span className="label-loan-detail">Thu nhập:</span>
                    <span className="value-loan-detail">Tối thiểu 3 triệu</span>
                </div>
                <div className="infoRow-loan-detail">
                    <span className="label-loan-detail">Tuổi:</span>
                    <span className="value-loan-detail">Từ 18 tuổi</span>
                </div>
            </div>
        </div>
    );
}

export default LoanDetailPageView;
