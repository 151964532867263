import React, { useEffect, useState } from 'react';
import "./PromotionPageView.scss"
import * as data from "@assets/data/data.module.json"
import "@assets/css/landing-page.css"
import {
    CardCity, PromotionCards, PromotionsCardsGroup
} from "../../shared";
import free from "@assets/images/label-free.png"
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import { Select } from "@components/elements";
import { Select2Data, Select2UpdateValue } from "select2-react-component";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";

const PromotionPageView = () => {
    const [bankCode, setBankCode] = useState<string>("VPBANK");

    const banks: Select2Data = JSON.parse(JSON.stringify([
        { value: "VIB", label: "Ngân Hàng Quốc Tế Việt Nam (VIB)" },
        { value: "VPBANK", label: "Ngân hàng TMCP Việt Nam Thịnh Vượng (VPBank)" },
        { value: "VIETCOMBANK", label: "Ngân hàng TMCP Ngoại thương Việt Nam (Vietcombank)" },
        { value: "TPBANK", label: "Ngân hàng Thương mại Cổ phần Tiên Phong (TPBank)" },
        { value: "AGRIBANK", label: "Ngân hàng Nông nghiệp và Phát triển Nông thôn Việt Nam (Agribank)" },
        { value: "HSBC", label: "Ngân hàng TNHH Một thành viên HSBC (HSBC)" },
        { value: "TECHCOMBANK", label: "Ngân hàng Thương mại cổ phần Kỹ thương Việt Nam (Techcombank)" },
    ]));
    useEffect(() => {
        document.title = "Ưu dãi";
        localStorage.removeItem("cardsCompare");
    }, [data.partners]);

    const onSelectBank = (e: Select2UpdateValue) => {
        const value = e as string;
        setBankCode(value)
    }
    return (
        <>
            <div className="container-fluid promotion">
                <div className="row">
                    <main className="col-md-12 ms-sm-auto col-lg-12">
                        <div className="row justify-content-center">
                            {/*<div className="banner-form">*/}
                            {/*    <h1></h1>*/}
                            {/*    <div className="form-home">*/}
                            {/*        <div className="text-form text-center">*/}
                            {/*            Tư vấn nhanh thẻ tín dụng phù hợp nhất với bạn*/}
                            {/*        </div>*/}
                            {/*        <ModalLead setLead={setLead} lead={lead} errors={errors} setErrors={setErrors}*/}
                            {/*                   openModalPrivacyConfirm={openModalPrivacyConfirm}*/}
                            {/*                   setIsPrivacy={() => setIsPrivacy(true)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="d-flex justify-content-center" style={{ backgroundColor: "#fff" }}>
                                <div id="top" className="row col-12 col-md-11 col-lg-10 tab-promotion my-4">

                                    <Tabs defaultActiveKey="1" size="large">
                                        <TabPane tab="Tất cả" key="1">
                                            <PromotionCards />
                                        </TabPane>
                                        <TabPane tab="Ưu đãi từ Finzoo" key="2">
                                            <PromotionsCardsGroup code={"finzoo"} />
                                        </TabPane>
                                        <TabPane tab="Ưu đãi từ các ngân hàng khác" key="3">
                                            <div className="col-md-4 col-12 d-flex justify-content-center">
                                                <Select placeholder="Chọn ngân hàng" onSelect={onSelectBank}
                                                    value={bankCode} error="" label="Lựa chọn ngân hàng"
                                                    data={banks}
                                                    required={false} image={undefined}
                                                />
                                            </div>
                                            <PromotionsCardsGroup code={bankCode} />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>

                        </div>

                        <div className="tu-van-card">
                            <div className="top-tu-van d-flex align-items-center justify-content-center">
                                <span className="top-title-tu-van">Quá nhiều thẻ tín dụng không biết nên chọn thẻ tín dụng nào ?</span>
                                <button onClick={() => {
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }
                                } className="dg-card-v2-btn-dk01"
                                    style={{ backgroundColor: "#FFA20C" }}>
                                    <span>Tư vấn ngay</span>
                                    <img className="default-label" src={free} alt="free" />
                                </button>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollToTopButton />
            </div>
            <CardCity />
        </>
    );
};

export default React.memo(PromotionPageView);
