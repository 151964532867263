import { API_PREFIX } from "@utils/config";
import axios from "axios";

const URL: string = `${API_PREFIX}`
axios.defaults.withCredentials = true;

class PublicService {
    async getAllEmailDomains() {
        return await axios.get(`${URL}/get-all-email-domains`)
    }

    async getAllCardTypes() {
        return await axios.get(`${URL}/get-all-card-types`)
    }

    async getAllLoanPackages() {
        return await axios.get(`${URL}/get-all-loan-packages`)
    }

    async getAllCreditCard() {
        return await axios.get(`${URL}/get-all-credit-cards`)
    }

    async getAllCreditCardByPartner(partner: string) {
        return await axios.get(`${URL}/product/list-product`, {
            params: { partner }
        });
    }

    async getAllLoanByPartner(partner: string) {
        return await axios.get(`${URL}/product/list-product`, {
            params: { partner }
        });
    }

    async applyRegister(step: string, type: string, lead: any) {
        // Kiểm tra nếu uuid không tồn tại trong sessionStorage
        let uuid = sessionStorage.getItem('lead_id');
        if (!uuid) {
            // Tạo một UUID mới và lưu vào sessionStorage
            uuid = crypto.randomUUID();
            sessionStorage.setItem('lead_id', uuid);
        }
    
        lead['token'] = sessionStorage.getItem('token');
        lead['ip'] = sessionStorage.getItem('ip');
        lead['link'] = window.location.href;
        lead['uuid'] = uuid;
    
        const url = `${URL}/apply-register?step=${step}&type=${type}`;
        return await axios.post(url, lead);
    }    

    // Thêm hàm verifyEmail
    async verifyEmail(emailData: { email: string }) {
        return await axios.post(`${URL}/email/email-validations`, emailData);
    }
}

const publicService = new PublicService();
export default publicService;
