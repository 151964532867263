export const images = {
    gocard: {
        zaloImage: 'https://img.dangky.info/gocard/zalo-image.webp',
        logoFooter: 'https://img.dangky.info/finzoo/icons/logo-footer.svg',
        logo: 'https://img.dangky.info/finzoo/icons/logo.svg',
        logoHeader: 'https://img.dangky.info/finzoo/icons/logo-header.svg',
        cardIcon: 'https://img.dangky.info/gocard/icons/card.svg',
        loanIcon: 'https://img.dangky.info/gocard/icons/loan.svg',
        banner: 'https://img.dangky.info/finzoo/banner.png',
        maskGroup1: 'https://img.dangky.info/gocard/mask-group-1.webp',
        maskGroup2: 'https://img.dangky.info/gocard/mask-group-2.webp',
        maskGroup: 'https://img.dangky.info/gocard/mask-group.webp',
        success: 'https://img.dangky.info/gocard/success.png',
        logoSuccess: 'https://img.dangky.info/gocard/icons/logo1.png',
        successImage: 'https://img.dangky.info/gocard/success-img.webp',
    },
    tnex: {
        logoHeader: 'https://img.dangky.info/gocard/icons/logo-tnex-gocard.svg',
        tnexBanner: 'https://img.dangky.info/gocard/Tnex-banner.png'
    },
    vpbank: {
        logoHeader: '',
    }
}