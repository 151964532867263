import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { notification, Spin } from 'antd';
import './ContactInfoPageView.scss';
import publicService from "../../../service/PublicService";

interface RouteParams {
    type: string;
}

interface ContactInfoState {
    phone: string;
    emailUsername: string;
    emailDomain: string;
    validPhone: boolean;
    validEmail: boolean;
    errorMail: string;
}

interface CreditCard {
    name: string;
    creditLimitTo: number;
    monthlyIncome: number;
}

interface LocationState {
    cardItem: CreditCard;
}

const ContactInfoPageView: React.FC = () => {
    window.scrollTo(0, 0);
    const { type } = useParams<RouteParams>();
    const history = useHistory();
    const [api, contextHolder] = notification.useNotification();
    const location = useLocation<LocationState>();
    const { cardItem } = location.state || {};

    // Kiểm tra tham số verify-otp từ URL
    const queryParams = new URLSearchParams(location.search);
    const verifyOtp = queryParams.get('verify-otp') === 'true';

    const [contactInfo, setContactInfo] = useState<ContactInfoState>({
        phone: '',
        emailUsername: '',
        emailDomain: 'gmail.com',
        validPhone: true,
        validEmail: true,
        errorMail: '',
    });

    const [emailDomains, setEmailDomains] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChangePhoneNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^\d{0,10}$/; // Allows up to 10 digits
        if (value.length >= 11) {
            return;
        }
        if (regex.test(value)) {
            setContactInfo((prevState) => ({
                ...prevState,
                phone: value,
                validPhone: value.length === 10 && /^\d{10}$/.test(value),
            }));
        }
    };

    const handleChangeEmailUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^[A-Za-z0-9]*$/; // Only allows letters and numbers
        setContactInfo((prevState) => ({
            ...prevState,
            emailUsername: value,
        }));

        if (regex.test(value)) {
            setContactInfo((prevState) => ({
                ...prevState,
                errorMail: '',
                validEmail: true,
            }));
        } else {
            setContactInfo((prevState) => ({
                ...prevState,
                errorMail: 'Tên email không được chứa ký tự đặc biệt',
                validEmail: false,
            }));
        }
    };

    const handleChangeEmailDomain = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setContactInfo((prevState) => ({
            ...prevState,
            emailDomain: value,
        }));
    };

    const handleEmailBlur = async () => {
        // Tạo địa chỉ email hoàn chỉnh từ username và domain
        const email = `${contactInfo.emailUsername.trim()}@${contactInfo.emailDomain.trim()}`;

        if (contactInfo.emailUsername.trim() === '') {
            setContactInfo((prevState) => ({
                ...prevState,
                validEmail: false,
                errorMail: 'Vui lòng nhập tên email',
            }));
            return;
        }

        try {
            // Gửi yêu cầu kiểm tra email đến backend với địa chỉ email đầy đủ
            const response = await publicService.verifyEmail({ email });
            console.log('API Response:', response);

            if (response.data.isValid) {
                // localStorage.setItem('userEmail', email);
                setContactInfo((prevState) => ({
                    ...prevState,
                    validEmail: true,
                    errorMail: '', // Nếu email hợp lệ, không hiển thị lỗi
                }));
            } else {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validEmail: false,
                    errorMail: 'Email không hợp lệ hoặc không thể gửi được.',
                }));
            }
        } catch (error) {
            console.error('API Error:', error);
            setContactInfo((prevState) => ({
                ...prevState,
                validEmail: false,
                errorMail: 'Đã xảy ra lỗi khi xác thực email. Vui lòng thử lại sau.',
            }));
        }
    };

    const handleOnSubmitCard = async (step: string) => {
        const { phone, emailUsername, emailDomain, validPhone, validEmail } = contactInfo;

        if (!validPhone || !validEmail) {
            return;
        }

        if (phone === '' || emailUsername === '') {
            if (phone === '') {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validPhone: false,
                }));
            }
            if (emailUsername === '') {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validEmail: false,
                    errorMail: 'Vui lòng nhập tên email',
                }));
            }
            return;
        }

        const leadDto = {
            phone: phone,
            email: `${emailUsername}@${emailDomain}`,
            product: cardItem?.name,
            creditLimit: cardItem?.creditLimitTo,
            monthlyIncome: cardItem?.monthlyIncome,
        };

        setLoading(true);
        const existingLoanInfo = sessionStorage.getItem('loanInfo');
        const loanInfo = existingLoanInfo ? JSON.parse(existingLoanInfo) : {};
        loanInfo.phone = phone;
        sessionStorage.setItem('loanInfo', JSON.stringify(loanInfo));

        try {
            const response = await publicService.applyRegister(step, type, leadDto);
            if (response.status === 200) {
                history.push(`/receive-OTP/${type}`, {
                    phone: phone,
                    emailUsername: emailUsername,
                    emailDomain: emailDomain,
                });
            }

            if (verifyOtp) {
                // Nếu verify-otp=true, bỏ qua bước OTP và chuyển thẳng đến trang PersonalInfoPageView
                history.push(`/info/${type}`, {
                    phone: phone,
                    emailUsername: emailUsername,
                    emailDomain: emailDomain,
                });
                return;
            }
        } catch (error: any) {
            api.error({
                message: 'Error',
                description: error.response.data,
            });
        } finally {
            setLoading(false);
        }
    };


    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // Kiểm tra lại email trước khi submit
        await handleEmailBlur();

        if (!contactInfo.validEmail) {
            // Nếu email không hợp lệ, không cho phép submit
            return;
        }

        handleOnSubmitCard('nhap-thong-tin-lien-he');
    };

    const fetchEmailDomains = async () => {
        const response = await publicService.getAllEmailDomains();
        if (response.status === 200) {
            setEmailDomains(response.data);
        }
    };

    useEffect(() => {
        fetchEmailDomains();
    }, []);

    return (
        <>
            {contextHolder}
            <div className="contact-form">
                <Spin spinning={loading}>
                    <p className="title-page" style={{ fontWeight: 1000, marginBottom: '0.2em' }}>
                        NHẬP THÔNG TIN LIÊN HỆ
                    </p>
                    <p className="svn-font" style={{ fontSize: '14px', fontWeight: '400' }}>
                        Vui lòng điền số điện thoại và email của bạn để chúng tôi tìm những đối tác tốt nhất cho bạn
                    </p>
                    <div className="mb-3 contact-input">
                        <p className="fw-semibold m-0 svn-bold" style={{ fontSize: '14px', fontWeight: '600' }}>
                            Số điện thoại
                        </p>
                        <input
                            type="text"
                            className="form-control border border-1"
                            id="phoneNumber"
                            pattern="\d*"
                            placeholder="Vui lòng nhập"
                            value={contactInfo.phone}
                            onChange={handleChangePhoneNo}
                        />
                        {!contactInfo.validPhone && (
                            <p className="text-danger">Vui lòng nhập 10 chữ số</p>
                        )}
                    </div>
                    <div className="mb-3 contact-input">
                        <p className="fw-semibold m-0 svn-bold" style={{ fontSize: '14px', fontWeight: '600' }}>
                            Email
                        </p>
                        <div className="d-flex align-items-center">
                            <input
                                type="text"
                                className="form-control border border-black"
                                placeholder="Tên email"
                                value={contactInfo.emailUsername}
                                onChange={handleChangeEmailUsername}
                                onBlur={handleEmailBlur} // Thêm sự kiện onBlur để gọi API
                            />
                            <select
                                className="form-control border border-black ms-2"
                                value={contactInfo.emailDomain}
                                onChange={handleChangeEmailDomain}
                            >
                                {emailDomains.map((domain: any) => (
                                    <option key={domain.id} value={domain.value}>
                                        {domain.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {!contactInfo.validEmail && <p className="text-danger">{contactInfo.errorMail}</p>}
                    </div>
                    <div className="d-flex justify-content-center">
                        <button
                            className="btn-search"
                            style={{ backgroundColor: '#105D38' }}
                            onClick={handleSubmit}>
                            {type === 'credit-card' ? 'Tìm thẻ phù hợp' : 'Tìm khoản vay phù hợp'}
                        </button>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default React.memo(ContactInfoPageView);
