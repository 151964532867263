import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';
import './ListCardPageView.scss';
import publicService from "../../../service/PublicService";
import { notification, Spin } from "antd";

interface LocationState {
    listCard: any;
    filterType: string;
}

const ListCardPageView = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { listCard, filterType } = location.state || {};
    const [activeButton, setActiveButton] = useState<string>(filterType);
    const [cardTypes, setCardTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorNotify, contextHolder] = notification.useNotification();
    const [cardType, setCardType] = useState(filterType);
    const handleButtonClick = (buttonType: string) => {
        setCardType(buttonType);
        setActiveButton(buttonType);
    };

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const filterCreditCards = (listCard: any, filterType: string) => {
        if (filterType === 'Tất cả') {
            return listCard;
        }
        return listCard.filter((card: any) => card.types.includes(filterType));
    };

    const resultCard = filterCreditCards(listCard, cardType);
    console.log(resultCard);

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const handleDetailCard = (cardItem: any) => {
        history.push('/card-detail', { cardItem: cardItem });
    }
    const handleCreate = async (card: any) => {
        const info = {
            product: card.name,
        }
        setLoading(true);
        try {
            const resp = await publicService.applyRegister('dang-ky', 'credit-card', info);
            if (resp.status === 200) {
                history.push('/success', { cardItem: card })
                setLoading(false);
            }
        } catch (error: any) {
            errorNotify.error({
                message: 'Lỗi',
                description: error.response?.data || 'An error occurred',
            });
        } finally {
            setLoading(false);
        }
    }

    const fetchCardTypes = async () => {
        const response = await publicService.getAllCardTypes();
        if (response.status === 200) {
            setCardTypes(response.data);
        }
    };

    useEffect(() => {
        fetchCardTypes();
    }, []);

    return (
        <>
            {contextHolder}
            <div className='container'>
                <Spin spinning={loading}>
                    <div className='btn-list-card'>
                        <button className={`btn-all ${activeButton === 'Tất cả' ? 'active' : ''}`}
                            onClick={() => handleButtonClick('Tất cả')}>
                            Tất cả
                            <div className="line"></div>
                        </button>
                        {cardTypes.map((type: any) => (
                            <button className={`btn-all ${activeButton === type.value ? 'active' : ''}`}
                                onClick={() => handleButtonClick(type.value)}>
                                {type.value}
                                <div className="line"></div>
                            </button>
                        ))}
                    </div>
                    <h1 className='text-list-card'>DANH SÁCH THẺ TÍN DỤNG</h1>
                    <div className='list'>
                        {resultCard.map((card: any) => (
                            <div className='list-card mb-3'>
                                <div className='card-icon'>
                                    <img src={card.iconUrl} alt="card-icon" />
                                </div>
                                <span className='pt-3'>{card.name}</span>
                                <div className='card-open row'>
                                    <div className="col-6"><img src={card.imageUrl} alt="card" /></div>
                                    <div className="col-6 d-flex flex-column justify-content-center align-items-center open-card">
                                        <button className="mb-2" onClick={() => handleCreate(card)}>Mở thẻ ngay
                                        </button>
                                        <a onClick={() => handleDetailCard(card)}>Tìm hiểu thêm &gt;</a>
                                    </div>
                                </div>
                                <div className='credit-limit mt-4'>
                                    <span className='span-title'>Hạn mức tín dụng:</span>
                                    <span className="span-content">
                                        {card.creditLimitFrom} triệu - {card.creditLimitTo} triệu
                                    </span>
                                </div>
                                <div className='credit-limit mt-2'>
                                    <span className='span-title'>Phí thường niên:</span>
                                    <span className="span-content">{formatNumberCustom(card.annualFee)} VNĐ</span>
                                </div>
                                <div className='credit-limit mb-2'>
                                    <span className='span-title'>Thu nhập:</span>
                                    <span className="span-content">Tối thiểu {card.monthlyIncome} triệu</span>
                                </div>
                            </div>
                        ))}
                        {/*<a href="#" className='see-more'>Xem thêm -&gt;</a>*/}
                    </div>

                    <ScrollToTopButton />
                </Spin>
            </div>
        </>
    )
}

export default React.memo(ListCardPageView);