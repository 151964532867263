import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import './ListCardPageView1.scss';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';

interface CreditCard {
  id: number;
  name: string;
  iconUrl: string;
  imageUrl: string;
  creditLimitFrom: number;
  creditLimitTo: number;
  annualFee: number;
  monthlyIncome: number;
  partner: string;
  navigateLink: string;
  cardType: string;
  interestRate: string;
}

const getAllCreditCard = async (partner?: string) => {
  try {
    if (partner) {
      const resp = await publicService.getAllCreditCardByPartner(partner);
      return resp.data;
    } else {
      const resp = await publicService.getAllCreditCard();
      return resp.data;
    }
  } catch (error) {
    console.error('Error fetching credit card data:', error);
    return [];
  }
}

const ListCardPageView1: React.FC = () => {
  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error] = useState<string | null>(null);
  const [visibleCount, setVisibleCount] = useState(3);
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const partner = searchParams.get('partner') || '';

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllCreditCard(partner);
      setCreditCards(data);
      setLoading(false);
    };

    fetchData();
    window.scroll(0, 0);
  }, [partner]);

  function formatNumberCustom(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const handleDetailCard = (cardItem: CreditCard) => {
    history.push('/chi-tiet-the', { cardItem: cardItem });
  }

  const handleCreateCard = async (step: string, type: string, cardItem: CreditCard) => {
    try {
      // Tạo một token UUID và lưu trữ trong sessionStorage
      const token = crypto.randomUUID();
      sessionStorage.setItem('token', token);

      // Tạo một đối tượng cardData chỉ chứa các trường cần thiết
      const cardData = {
        product: cardItem.name,
        creditLimit: cardItem.creditLimitTo,
        monthlyIncome: cardItem.monthlyIncome,
      };

      // Lưu cardItem gốc vào localStorage
      localStorage.setItem('cardData', JSON.stringify(cardItem));

      // Gửi dữ liệu đến API chỉ với các trường cần thiết
      const response = await publicService.applyRegister(step, type, cardData);
      if (response.status === 200) {
        // Chuyển đến trang thông tin liên hệ kèm theo cardData
        history.push('/contact-info/credit-card', { cardItem: cardData });
      } else {
        console.error('Đăng ký thẻ tín dụng thất bại:', response);
      }
    } catch (error) {
      console.error('Lỗi khi đăng ký thẻ tín dụng:', error);
    }
  };

  if (loading) {
    return <p style={{ fontFamily: 'SVN-Regular', textAlign: 'center', marginTop: '40px' }}>Đang tải...</p>;
  }

  if (error) {
    return <p style={{ fontFamily: 'SVN-Regular', textAlign: 'center', marginTop: '40px' }}>{error}</p>;
  }

  const handleSeeMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  return (
    <>
      <div className='container'>
        <h1 className='text-list-card'>DANH SÁCH THẺ TÍN DỤNG</h1>
        <div className='list'>
          {creditCards.slice(0, visibleCount).map((card: CreditCard) => (
            <div className='list-card mb-3' key={card.id}>
              <div className='card-icon'>
                <img src={card.iconUrl} alt="card-icon" />
              </div>
              <span className='pt-3 text-bold'>{card.name}</span>
              <div className='card-open row'>
                <div className="col-6">
                  <img src={card.imageUrl} alt="card" />
                </div>
                <div className="col-6 d-flex flex-column justify-content-center align-items-center open-card">
                  <button className="mb-2" onClick={() => handleCreateCard("dang-ky-the-tin-dung", 'credit-card', card)}>Mở thẻ ngay</button>
                  <a onClick={() => handleDetailCard(card)}>Tìm hiểu thêm &gt;</a>
                </div>
              </div>
              <div className='credit-limit mt-4'>
                <span className='span-title'>Loại thẻ:</span>
                <span className="span-content">
                  {card.cardType}
                </span>
              </div>
              <div className='credit-limit mt-2'>
                <span className='span-title'>Phí thường niên:</span>
                <span className="span-content">{formatNumberCustom(card.annualFee)} VNĐ</span>
              </div>
              <div className='credit-limit mt-2'>
                <span className='span-title'>Lãi suất năm:</span>
                <span className="span-content">{card.interestRate}</span>
              </div>
            </div>
          ))}
          {visibleCount < creditCards.length && (
            <a className='see-more' onClick={handleSeeMore}>Xem thêm -&gt;</a>
          )}
        </div>
        <ScrollToTopButton />
      </div>
    </>
  );
}

export default React.memo(ListCardPageView1);
