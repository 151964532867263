import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './CardDetailPageView1.scss';
import publicService from "../../../service/PublicService";

interface LocationState {
    cardItem: any
}

interface CreditCard {
    id: number;
    name: string;
    imageUrl: string;
    creditLimitFrom: number;
    creditLimitTo: number;
    annualFee: number;
    monthlyIncome: number;
    partner: string;
    navigateLink: string;
}

const CardDetailPageView1 = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { cardItem } = location.state || {};
    console.log(cardItem);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    // Nếu features là một chuỗi JSON, hãy parse nó thành mảng
    let features: string[] = [];
    if (typeof cardItem.features === 'string') {
        try {
            features = JSON.parse(cardItem.features);
        } catch (e) {
            console.error('Error parsing features:', e);
        }
    } else {
        features = cardItem.features || [];
    }

    const handleCreateCard = async (step: string, type: string, cardItem: CreditCard) => {
        try {
            // Create a new object containing only the necessary fields
            const cardData = {
                product: cardItem.name,
                creditLimit: cardItem.creditLimitTo,
                monthlyIncome: cardItem.monthlyIncome,
            };

            // Save the cardData to localStorage
            localStorage.setItem('cardData', JSON.stringify(cardItem));

            // Send only the three specified fields to the API
            const resp = await publicService.applyRegister(step, type, cardData);
            if (resp.status === 200) {
                // Pass the card information to the next page
                history.push('/contact-info/credit-card', { cardItem: cardData });
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="mx-3 my-3 fs-1 container-card-details">
            <p className="card-detail-header">Chi tiết thẻ</p>
            <div className="border border-2 px-4 py-4 card-wrapper"
                style={{ backgroundColor: '#edfe8c', borderColor: '' }}>
                <p className="fw-bold card-name">{cardItem.name}</p>
                <div className="d-flex flex-row justify-content-around align-item">
                    <img className='img-card' src={cardItem.imageUrl} alt="card" />
                    <div className="ps-2 open-card">
                        <div className="w-100 position-relative">
                            <button
                                className="rounded-pill open-card-btn"
                                onClick={() => handleCreateCard("dang-ky-the-tin-dung", 'credit-card', cardItem)}
                            >Mở thẻ ngay
                            </button>
                            <a className='back' href="/danh-sach-the-tin-dung">&lt; Quay lại</a>
                        </div>
                    </div>
                </div>
                <div className="container card-detail-grid">
                    <div className="row px-0">
                        {/* {cardItem.map(() => ( */}
                        <div
                            className="col-12 d-grid justify-content-between py-2 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Loại thẻ</div>
                            <div className="text-start left-col">
                                {cardItem.cardType}
                            </div>
                        </div>
                        <div
                            className="col-12 d-grid justify-content-between py-2 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Phí thường niên</div>
                            <div className="text-start left-col">
                                {cardItem.annualFee} VNĐ
                            </div>
                        </div>
                        <div
                            className="col-12 d-grid justify-content-between py-0 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Lãi suất năm</div>
                            <div className="text-start left-col">
                                {cardItem.interestRate}
                            </div>
                        </div>
                        <div
                            className="col-12 d-flex justify-content-between py-2 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Tính năng nổi bật</div>
                        </div>
                        {/* ))} */}
                    </div>
                </div>
                <div className="container-details-card">
                    <div>
                        {features.map((feature: string, index: number) => (
                            <div
                                key={index}
                                className={`text-start d-grid tag ${index === 0 ? "first-feature" : ""
                                    }`}
                            >
                                <div
                                    className="fw-bold"
                                    dangerouslySetInnerHTML={{ __html: feature }}
                                ></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CardDetailPageView1);