import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Turnstile from 'react-turnstile';
import publicService from 'src/app/service/PublicService';

interface LoanItem {
    id: number;
    name: string;
    imageUrl: string;
    loanLimit: number;
    term: number;
    interestRate: number;
    benefits: number;
}

const RegisterLoanPageView: React.FC = () => {
    const [loanAmount, setLoanAmount] = useState<number>(3000000);
    const [loanTerm, setLoanTerm] = useState<number>(1);
    const [agreement, setAgreement] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [checkCaptcha, setCheckCaptcha] = useState(true);

    const location = useLocation<{ loanItem: LoanItem }>();
    const history = useHistory();

    // Extract LoanItem from location state
    const loanItem = location.state?.loanItem;

    useEffect(() => {
        if (!loanItem) {
            // If loanItem is not available, redirect to previous page or show an error
            history.push('/list-name-loan'); // Redirect to the loan list page
        }
    }, [loanItem, history]);

    // Format number with commas
    const formatNumberWithCommas = (number: number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formattedLoanAmount = formatNumberWithCommas(loanAmount);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        setLoanAmount(value);
    };

    const LoanAmountBackground = `linear-gradient(
        to right,
        #000 ${((loanAmount - 3000000) / (20000000 - 3000000)) * 100}%,
        #aaa ${((loanAmount - 3000000) / (20000000 - 3000000)) * 100}%
    )`;

    const LoanTermBackground = `linear-gradient(
        to right,
        #000 ${((loanTerm - 1) / (12 - 1)) * 100}%,
        #aaa ${((loanTerm - 1) / (12 - 1)) * 100}%
    )`;

    const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoanTerm(parseInt(e.target.value, 10));
    };

    const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgreement(e.target.value);
    };

    const handleSearch = async (step: string, type: string) => {
        if (agreement === 'agree') {
            const loanInfo = {
                ...loanItem,
                loanAmount,
                loanTerm,
            };
            sessionStorage.setItem('loanInfo', JSON.stringify(loanInfo));
            setLoading(true);
            try {
                const resp = await publicService.applyRegister(step, type, loanInfo);
                if (resp.status === 200) {
                    history.push(`/contact-info/loan`);
                } else {
                    console.log(resp);
                }
            } catch (error) {
                console.error("Lỗi khi đăng ký khoản vay:", error);
            } finally {
                setLoading(false);
            }
        } else {
            alert("Bạn phải đồng ý với Điều khoản sử dụng để tiếp tục.");
        }
    };

    const verifyCaptcha = (token: string) => {
        sessionStorage.setItem('token', token);
        setCheckCaptcha(false);
        console.log("Captcha verified");
    };

    if (!loanItem) {
        return null; // or show a loader or redirect to an error page
    }

    return (
        <>
            <Spin spinning={loading}>
                <div className="loan-container" style={{marginTop: '40px'}}>
                    <h2 className="loan-title">ĐĂNG KÝ KHOẢN VAY</h2>
                    <div className="loan-amount">
                        <label htmlFor="customRange1" className="form-label">
                            <span>Số tiền cần vay</span>
                            <input 
                                type="text"
                                className="loan-amount-input"
                                value={formattedLoanAmount}
                                readOnly
                            />
                        </label>
                        <div className="slider-container">
                            <input
                                type="range"
                                className="form-range"
                                min="3000000"
                                max={loanItem.loanLimit} // Max limit based on loanItem
                                step="1000000"
                                id="customRange1"
                                style={{ background: LoanAmountBackground }}
                                value={loanAmount}
                                onChange={handleSliderChange}
                            />
                            <p className="slider-label">3,000,000</p>
                            <p className="slider-label">{formatNumberWithCommas(loanItem.loanLimit)}</p>
                        </div>
                    </div>
                    <div className="loan-term">
                        <label htmlFor="customRange2" className="form-label">
                            <span>Kỳ hạn vay</span>
                            <input 
                                type="text"
                                className="loan-term-input"
                                value={loanTerm} 
                                readOnly
                            />
                        </label>
                        <div className="slider-container">
                            <input
                                type="range"
                                className="form-range"
                                min="1"
                                max={loanItem.term} // Max term based on loanItem
                                id="customRange2"
                                style={{ background: LoanTermBackground }}
                                value={loanTerm}
                                onChange={handleTermChange}
                            />
                            <p className="slider-label">1 tháng</p>
                            <p className="slider-label">{loanItem.term} tháng</p>
                        </div>
                    </div>
                    <div className="terms-container">
                        <p>Để đăng ký và sử dụng dịch vụ từ Finzoo, xin vui lòng đọc và đồng ý với
                            <a href="/dieu-khoan-su-dung">Điều khoản sử dụng dịch vụ</a>
                            của chúng tôi.
                        </p>
                        <div className="terms-options">
                            <div className="form-check">
                                <div>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault1"
                                        value="agree"
                                        onChange={handleAgreementChange}
                                        checked={agreement === 'agree'}
                                    />
                                </div>
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Tôi đồng ý và muốn sử dụng dịch vụ.
                                </label>
                            </div>
                            <div className="form-check">
                                <div>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        value="disagree"
                                        onChange={handleAgreementChange}
                                        checked={agreement === 'disagree'}
                                        style={{ alignSelf: 'flex-start', marginTop: '0.8rem' }}
                                    />
                                </div>
                                <label className="form-check-label" htmlFor="flexRadioDefault2" style={{ lineHeight: '14px' }}>
                                    Tôi không đồng ý với toàn bộ hoặc một phần<br /> trong Điều khoản dịch vụ.
                                </label>
                            </div>
                        </div>
                    </div>
                    <Turnstile
                        className="varify"
                        sitekey="0x4AAAAAAAgoq9QLELbUz05a"
                        onVerify={(token: string) => verifyCaptcha(token)}
                    />
                    <button
                        className="loan-submit-button"
                        disabled={checkCaptcha}
                        onClick={() => handleSearch("dang-ky-khoan-vay", 'loan')}
                    >
                        Đăng ký khoản vay
                    </button>
                </div>
            </Spin>
        </>
    );
}

export default React.memo(RegisterLoanPageView);
