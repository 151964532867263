import React from 'react';
import './SuccessView.scss';
import {images} from '@assets/images';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';

const SuccessView = () => {
    const handleClick = () => {
        window.location.href = '/';
    }

    return (
        <>
            <div className='container'>
                <div className='img'>
                    <img className='img-success' src={images.gocard.successImage} alt="success-image" />
                </div>
                <span className='label-success svn-bold'>Thành công</span>
                <p className='txt-success svn-font'>Bạn đã đăng ký thành công khoản vay của Tnex Finance.
                    Vui lòng để ý điện thoại của bạn, nhân viên tư vấn sẽ liên hệ tư vấn trong vòng 24h
                </p>
                <div className='btn-back'>
                    <button onClick={handleClick}>Quay về trang chủ</button>
                </div>
                {/*<div className='advert-container'>
                    <div className='advert'>
                        <div className='img-advert'>
                            <img src="" alt="" />
                        </div>
                        <span>Lãi suất hấp dẫn chỉ từ 6.4%/năm</span>
                        <div className='text-advert'>
                            <p>Lãi xuất<br /> <b>6.4%</b> <br />Năm</p>
                            <p>Ưu đãi<br />  <b>12</b> <br />Tháng</p>
                        </div>
                        <button>Đăng ký ngay</button>
                        <a href="#">Xem chi tiết</a>
                    </div>
                </div>*/}
                <ScrollToTopButton />
            </div>
        </>
    );
}

export default React.memo(SuccessView);
