import React from 'react';
import './IntroducePageView.scss';

const IntroducePageView = () => (
  <div className="px-4 pt-1 overflow-y-scroll introduce">
    <h1 className="introduce-heading">Finzoo.vn - Giới thiệu</h1>
    <p className="introduce-paragraph">Finzoo.vn là trang web giới thiệu, hỗ trợ tìm kiếm các loại dịch vụ ngân hàng như: Thẻ tín dụng, mở tài khoản tại các
      ngân hàng uy tín với các thủ tục đơn giản, nhanh gọn và 100% online.
    </p>
    <p className="introduce-paragraph" style={{ marginBottom: '0' }}>Không phải là một tổ chức tài chính, tín dụng, ngân hàng</p>
    <p className="introduce-paragraph">Finzoo.vn không phải là một tổ chức tài chính, tín dụng hay ngân hàng, đồng thời không đưa ra bất cứ quyết định
      nào liên quan đến tín dụng.
    </p>
    <p className="introduce-paragraph" style={{ marginBottom: '0' }}>Vai trò trung gian</p>
    <p className="introduce-paragraph">Finzoo.vn tập hợp các đề xuất dịch vụ ngân hàng cho khách hàng, đóng vai trò trung gian giữa khách hàng
      (người có nhu cầu mở thẻ tín dụng/mở tài khoản) và ngân hàng.
    </p>
    <p className="introduce-paragraph" style={{ marginBottom: '0' }}>Không cam kết</p>
    <p className="introduce-paragraph">Finzoo.vn không cam kết tất cả các hồ sơ đăng ký dịch vụ sẽ được phê duyệt và tốc độ phê duyệt sẽ phụ thuộc
      trực tiếp vào tiến độ xử lí của từng ngân hàng cung cấp dịch vụ.
    </p>
    <p className="introduce-paragraph" style={{ marginBottom: '0' }}>Cung cấp thông tin tham khảo</p>
    <p className="introduce-paragraph">Finzoo.vn chỉ thực hiện nhiệm vụ cung cấp thông tin tham khảo, bất kỳ danh mục nào trên trang web đều mang tính chất tương đối.
    </p>
  </div>
);

export default IntroducePageView;
