import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import "./ClientReport.scss"
import stars from "@assets/images/votefor5.webp"
import ava1 from "@assets/images/no_avatar.jpeg"
import ava2 from "@assets/images/no_avatar.jpeg"
import ava3 from "@assets/images/no_avatar.jpeg"
import {CustomArrowLeft, CustomArrowRight} from "@components/elements";

const ClientReport = () => {

    return (
        <>
            <div className="inner-partner text-center">
                <div className="label_result partner text-center mt-4">
                    <span>KHÁCH HÀNG NÓI GÌ VỀ CHÚNG TÔI</span>
                </div>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    customLeftArrow={<CustomArrowLeft/>}
                    customRightArrow={<CustomArrowRight/>}
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={false}
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    <div className="partner-item-gutters position-relative card-body card-body-slider">
                        <img src={ava2} height="100" alt="avatar"/>
                        <img src={stars}
                             height="20" alt="star"/><span>
                                    “Mình bán hàng online nên nhiều khi cần gấp 15-20 triệu để nhập hàng. Nhờ biết đến Finzoo mình đã mở được thẻ tin dụng phù hợp với mình”
                                    </span>
                        <span className="username">Anh Quang chia sẻ</span>
                    </div>
                    <div className="partner-item-gutters position-relative card-body card-body-slider">
                        <img src={ava1} height="100" alt="avatar"/>
                        <img src={stars}
                             height="20" alt="star"/><span>
                                     “Em có nhu cầu mở thẻ tín dụng, tuy nhiên hiện đang là sinh viên nên hầu hết các thẻ em biết em đều không đủ điều kiện mở. Nhưng qua Finzoo, em đã tìm được thẻ tín dụng dành riêng cho sinh viên”
                                    </span>
                        <span className="username">Chị Huyền chia sẻ</span>
                    </div>
                    <div className="partner-item-gutters position-relative card-body card-body-slider">
                        <img src={ava3} height="100" alt="avatar"/>
                        <img src={stars}
                             height="20" alt="star"/><span>
                                     “Mình thấy chi tiêu qua thẻ tín dụng rất hợp lý. Được hưởng nhiều ưu đãi như hoàn tiền hay giảm giá mua sắm. Vì thế để chọn ra thẻ phù hợp nhất với mục đích và nhu cầu của mình mình đã tìm và mở thẻ trên Finzoo”
                                    </span>
                        <span className="username">Chị Lan chia sẻ</span>
                    </div>
                </Carousel>
            </div>
        </>
    );
}

export default React.memo(ClientReport);
