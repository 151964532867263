import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import './ListLoanPageView.scss';
import {images} from "@assets/images";

interface LoanItem {
    id: number;
    name: string;
    partner?: string;
    iconUrl: string;
    loanLimit: number;
    term: number;
    interestRate: number;
    benefits: number;
}

const getAllLoanPackages = async (partner?: string) => {
    try {
        if (partner) {
            document.body.classList.add(`partner-${partner}`)
            const resp = await publicService.getAllLoanByPartner(partner);
            return resp.data;
        } else {
            document.body.classList.add(':root');
            const resp = await publicService.getAllLoanPackages();
            return resp.data;
        }
    } catch (error) {
        console.error("Error", error);
        return [];
    }
}

const ListLoanPageView: React.FC = () => {
    const [loanPackage, setLoanPackage] = useState<LoanItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error] = useState<string | null>(null);
    const history = useHistory();

    localStorage.setItem('link', window.location.href)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const partner = searchParams.get('partner') || '';

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAllLoanPackages(partner);
            setLoanPackage(data);
            setLoading(false);
        };

        fetchData();
        window.scroll(0, 0);
    }, [partner]);

    const handleCreateLoanPackage = (loanPackageItem: LoanItem) => {
        history.push('/register-loan', {loanItem: loanPackageItem, active: 'loan'});
    }

    const handleLoanDetail = (loanPackageItem: LoanItem) => {
        history.push({
            pathname: '/loan-detail',
            state: { loanItem: loanPackageItem },  // Truyền loanItem qua state
            search: `?partner=${partner}`  // Truyền partner qua URL
        });
    }

    if (loading) {
        return <p style={{fontFamily: 'SVN-Regular', textAlign: 'center', marginTop: '40px'}}>Đang tải...</p>;
    }

    if (error) {
        return <p style={{fontFamily: 'SVN-Regular', textAlign: 'center', marginTop: '40px'}}>{error}</p>;
    }

    return (
        <>
            <div className="banner-container">
                <img className='banner-tnex' src={images.tnex.tnexBanner} alt="Banner TNEX"/>
            </div>
            <h1 className='text-list-loan'>Danh sách sản phẩm vay</h1>
            {loanPackage.length > 0 ? (
                loanPackage.map((loanPackage: LoanItem) => (
                    <div key={loanPackage.id} className='advert-container'>
                        <div className='advert'>
                            <div className='img-advert'>
                                <img src={loanPackage.iconUrl} alt="loan"/>
                            </div>
                            <span>Lãi suất hấp dẫn chỉ từ {loanPackage.interestRate}%/năm</span>
                            <div className='text-advert'>
                                <p>Lãi suất<br/> <b>từ {loanPackage.interestRate}%</b> <br/>Năm</p>
                                <p>Ưu đãi<br/> <b>{loanPackage.term}</b> <br/>Tháng</p>
                            </div>
                            <button onClick={() => handleCreateLoanPackage(loanPackage)}>Đăng ký ngay</button>
                            <a className='see-detail-loan' onClick={() => handleLoanDetail(loanPackage)}>Xem chi
                                tiết</a>
                        </div>
                    </div>
                ))
            ) : (
                <p className='text'>Không có khoản vay nào để hiển thị.</p>
            )}
        </>
    );
}

export default React.memo(ListLoanPageView);
