import "@assets/css/landing-page.css";
import iconDown from '@assets/images/downward-arrow.png';
import React, { useEffect, useState } from 'react';
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import './HomePageView.scss';
import { useHistory, useLocation } from 'react-router-dom';
import publicService from "../../../service/PublicService";
import { Spin } from "antd";
import Turnstile from "react-turnstile";
import {images} from '@assets/images';

export interface ILead {
    name: string;
    phone: string;
    idCard: string;
    source: string;
    email: string;
    lead_status: number;
    confirm: string;
}

export interface Errors {
    name: string;
    phone: string;
    email: string;
    idCard: string;
}

interface LocationState {
    active?: string;
}

const HomePageView = () => {
    const [activeButton, setActiveButton] = useState<string>('loan');
    const [loanAmount, setLoanAmount] = useState<number>(3000000);
    const [loanTerm, setLoanTerm] = useState<number>(1);
    const [agreement, setAgreement] = useState<string | null>(null);
    const [income, setIncome] = useState('');
    const [cardType, setCardType] = useState('Tất cả');
    const [limit, setLimit] = useState('');
    const [warningIncome, setWarningIncome] = useState('');
    const [warningLimit, setWarningLimit] = useState('');
    const [loading, setLoading] = useState(false);
    const [cardTypes, setCardTypes] = useState([]);
    const [checkCaptcha, setCheckCaptcha] = useState(true);

    const location = useLocation<LocationState>();
    const history = useHistory();

    // Kiểm tra tham số verify-otp từ URL
    const queryParams = new URLSearchParams(location.search);
    const verifyOtp = queryParams.get('verify-otp') === 'true'; // Kiểm tra chính xác giá trị

    useEffect(() => {
        localStorage.removeItem("cardData");

        if (location.state && location.state.active) {
            setActiveButton(location.state.active);
        }

        $.getJSON("https://api.ipify.org?format=json", function (data) {
            sessionStorage.setItem("ip", data.ip);
        });

        sessionStorage.setItem("lead_id", crypto.randomUUID());
        if (sessionStorage.getItem("user_id") == null) {
            sessionStorage.setItem("user_id", crypto.randomUUID());
        }

        window.scrollTo(0, 0);
    }, [location.state]);

    const handleButtonClick = (buttonType: string) => {
        setActiveButton(buttonType);
    };

    const handleSliderChange = (e: any) => {
        const value = parseInt(e.target.value, 10);
        setLoanAmount(value);
    };

    const formatNumberWithCommas = (number: any) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formattedLoanAmount = formatNumberWithCommas(loanAmount);
    const handleTermChange = (e: any) => setLoanTerm(e.target.value);
    const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgreement(e.target.value);
    };

    const handleIncomeChange = (e: any) => {
        const value = e.target.value;
        if (value.length > 3) {
            return;
        }
        if (/^\d*$/.test(value) && (value === '' || (Number(value) <= 999 && Number(value) >= 1))) {
            setIncome(value);
            setWarningIncome('');
        } else if (value !== '') {
            setWarningIncome('Tối đa 999 triệu đồng.');
        } else {
            setWarningIncome('');
        }
    };

    const handleLimitChange = (e: any) => {
        const value = e.target.value;
        if (value.length > 3) {
            return;
        }
        if (/^\d*$/.test(value) && (value === '' || (Number(value) <= 999 && Number(value) >= 1))) {
            setLimit(value);
            setWarningLimit('');
        } else if (value !== '') {
            setWarningLimit('Tối đa 999 triệu đồng.');
        } else {
            setWarningLimit('');
        }
    };

    const handleSearch = async (step: string, type: string) => {
        if (type === 'loan') {
            if (agreement === 'agree') {
                const loanInfo = {
                    loanAmount,
                    loanTerm,
                };
                sessionStorage.setItem('loanInfo', JSON.stringify(loanInfo));
                setLoading(true);
                try {
                    const resp = await publicService.applyRegister(step, type, loanInfo);
                    if (resp.status === 200) {
                        // Điều hướng đến trang contact-info với tham số verify-otp nếu có
                        history.push(`/contact-info/loan${verifyOtp ? '?verify-otp=true' : ''}`);
                    } else {
                        console.log(resp);
                    }
                } catch (error) {
                    console.error("Lỗi khi đăng ký khoản vay:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                alert("Bạn phải đồng ý với Điều khoản sử dụng để tiếp tục.");
            }
        } else if (type === 'credit-card') {
            const cardInfo = {
                creditCardType: cardType,
                monthlyIncome: income,
                creditLimit: limit,
            };
            sessionStorage.setItem('cardInfo', JSON.stringify(cardInfo));
            setLoading(true);
            try {
                const resp = await publicService.applyRegister(step, type, cardInfo);
                if (resp.status === 200) {
                    // Điều hướng đến trang contact-info với tham số verify-otp nếu có
                    history.push(`/contact-info/credit-card${verifyOtp ? '?verify-otp=true' : ''}`);
                } else {
                    console.log(resp);
                }
            } catch (error) {
                console.error("Lỗi khi đăng ký thẻ tín dụng:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const LoanAmountBackground = `linear-gradient(
        to right,
        #000 ${((loanAmount - 3000000) / (20000000 - 3000000)) * 100}%,
        #aaa ${((loanAmount - 3000000) / (20000000 - 3000000)) * 100}%
    )`;

    const LoanTermBackground = `linear-gradient(
        to right,
        #000 ${((loanTerm - 1) / (12 - 1)) * 100}%,
        #aaa ${((loanTerm - 1) / (12 - 1)) * 100}%
    )`;

    const fetchCardTypes = async () => {
        const response = await publicService.getAllCardTypes();
        if (response.status === 200) {
            setCardTypes(response.data);
        }
    };

    const verifyCaptcha = (token: string) => {
        sessionStorage.setItem('token', token);
        setCheckCaptcha(false);
    };

    useEffect(() => {
        fetchCardTypes();
    }, []);

    return (
        <>
            <div className="container-fluid">
                <Spin spinning={loading}>
                    <div className="row">
                        <main className="col-md-12 ms-sm-auto col-lg-12">
                            <div className="row justify-content-center">
                                <div className="banner-form">
                                    <img src={images.gocard.banner} alt="banner" className="img-banner"/>
                                </div>
                                <div className='btn' style={{boxShadow: 'none'}}>
                                    <button className={`loan-btn ${activeButton === 'loan' ? 'active' : ''}`}
                                            onClick={() => handleButtonClick('loan')}>
                                        <img className='loan-icon' src={images.gocard.loanIcon} alt="icon vay tieu dung"/>
                                        Vay tiêu dùng
                                    </button>
                                    <div className="card-btn-container">
                                        <button className={`card-btn ${activeButton === 'card' ? 'active' : ''}`}
                                                onClick={() => handleButtonClick('card')}>
                                            <img className='card-icon' src={images.gocard.cardIcon} alt="icon the tin dung"/>
                                            Thẻ tín dụng
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {activeButton === 'loan' && (
                                        <div className="loan-container">
                                            <h2 className="loan-title">ĐĂNG KÝ KHOẢN VAY</h2>
                                            <div className="loan-amount">
                                                <label htmlFor="customRange1" className="form-label">
                                                    <span>Số tiền cần vay</span>
                                                    <input type="text"
                                                           className="loan-amount-input"
                                                           value={formattedLoanAmount} readOnly/>
                                                </label>
                                                <div className="slider-container">
                                                    <input
                                                        type="range"
                                                        className="form-range"
                                                        min="3000000"
                                                        max="20000000"
                                                        step="1000000"
                                                        id="customRange1"
                                                        style={{background: LoanAmountBackground}}
                                                        value={loanAmount}
                                                        onChange={handleSliderChange}/>
                                                    <p className="slider-label">3,000,000</p>
                                                    <p className="slider-label">20,000,000</p>
                                                </div>
                                            </div>
                                            <div className="loan-term">
                                                <label htmlFor="customRange2" className="form-label">
                                                    <span>Kỳ hạn vay</span>
                                                    <input type="text"
                                                           className="loan-term-input"
                                                           value={loanTerm} readOnly/>
                                                </label>
                                                <div className="slider-container">
                                                    <input
                                                        type="range"
                                                        className="form-range"
                                                        min="1"
                                                        max="12"
                                                        id="customRange2"
                                                        style={{background: LoanTermBackground}}
                                                        value={loanTerm}
                                                        onChange={handleTermChange}/>
                                                    <p className="slider-label">1 tháng</p>
                                                    <p className="slider-label">12 tháng</p>
                                                </div>
                                            </div>
                                            <div className="terms-container">
                                                <p>Để đăng ký và sử dụng dịch vụ từ Finzoo, xin vui lòng đọc và đồng ý với
                                                    <a href="/dieu-khoan-su-dung">Điều khoản sử dụng dịch vụ</a>
                                                    của chúng tôi.</p>
                                                <div className="terms-options">
                                                    <div className="form-check">
                                                        <div>
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault1"
                                                                value="agree"
                                                                onChange={handleAgreementChange}
                                                                checked={agreement === 'agree'}
                                                            />
                                                        </div>
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                            Tôi đồng ý và muốn sử dụng dịch vụ.
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <div>
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="flexRadioDefault"
                                                                id="flexRadioDefault2"
                                                                value="disagree"
                                                                onChange={handleAgreementChange}
                                                                checked={agreement === 'disagree'}
                                                                style={{alignSelf: 'flex-start', marginTop: '0.8rem'}}
                                                            />
                                                        </div>
                                                        <label className="form-check-label" htmlFor="flexRadioDefault2"
                                                               style={{lineHeight: '14px'}}>
                                                            Tôi không đồng ý với toàn bộ hoặc một phần<br/> trong Điều
                                                            khoản dịch vụ.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <Turnstile
                                                className="varify"
                                                sitekey="0x4AAAAAAAgoq9QLELbUz05a"
                                                onVerify={(token: any) => verifyCaptcha(token)}
                                            />
                                            <button
                                                className="loan-submit-button"
                                                disabled={checkCaptcha}
                                                onClick={() => handleSearch("dang-ky-khoan-vay", 'loan')}
                                            >Đăng ký khoản vay
                                            </button>
                                        </div>
                                    )}
                                    {activeButton === 'card' && (
                                        <div className="card-container">
                                            <h2 className="card-title">LỰA CHỌN THẺ TÍN DỤNG</h2>
                                            <span className='text-income'>Mức thu nhập hàng tháng</span>
                                            <div className="input-group mb-0">
                                                <input
                                                    type="text"
                                                    id="income"
                                                    placeholder="Vui lòng nhập"
                                                    max="999"
                                                    pattern="\d*" // Chỉ cho phép nhập số
                                                    value={income}
                                                    onChange={handleIncomeChange}
                                                />
                                                <span>triệu đồng</span>
                                            </div>
                                            {warningIncome &&
                                                <div className="warning-message-income">
                                                    {warningIncome}
                                                </div>
                                            }
                                            <span className='text-card-type mt-4'>Loại thẻ mong muốn</span>
                                            <div className="select-wrapper">
                                                <select
                                                    id="card-type"
                                                    className="select-card-type"
                                                    value={cardType}
                                                    onChange={(e) => setCardType(e.target.value)}
                                                >
                                                    <option value="Tất cả">Tất cả</option>
                                                    {cardTypes.map((type: any) => (
                                                        <option value={type.value}>{type.value}</option>
                                                    ))}
                                                </select>
                                                <img src={iconDown} alt="icon-down" className="icon-down"/>
                                            </div>
                                            <span className='text-limit'>Hạn mức mong muốn</span>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    id="limit"
                                                    placeholder="Vui lòng nhập"
                                                    max="999"
                                                    pattern="\d*" // Chỉ cho phép nhập số
                                                    value={limit}
                                                    onChange={handleLimitChange}
                                                />
                                                <span>triệu đồng</span>
                                                {warningLimit &&
                                                    <div className="warning-message-limit">
                                                        {warningLimit}
                                                    </div>
                                                }
                                            </div>
                                            <Turnstile
                                                className="varify"
                                                sitekey="0x4AAAAAAAgoq9QLELbUz05a"
                                                onVerify={(token: any) => verifyCaptcha(token)}
                                            />
                                            <button className="search-button"
                                                    disabled={checkCaptcha}
                                                    onClick={() => handleSearch("dang-ky-the-tin-dung", 'credit-card')}
                                            >Tìm kiếm ngay
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className='group'>
                                    <div className='mask-group'>
                                        <div>
                                            <span>ĐA DẠNG SẢN PHẨM</span>
                                            <p>Finzoo cung cấp thông tin chi tiết về hàng loạt sản phẩm cho vay và thẻ tín dụng từ các ngân hàng và tổ chức tài chính uy tín</p>
                                        </div>
                                        <img src={images.gocard.maskGroup} alt="mask-group"/>
                                    </div>
                                    <div className='mask-group-1'>
                                        <div>
                                            <span>SO SÁNH TOÀN DIỆN</span>
                                            <p>Người dùng có thể dễ dàng so sánh lãi suất, phí, và các điều khoản, giúp bạn chọn lựa sản phẩm phù hợp nhất với nhu cầu của mình</p>
                                        </div>
                                        <img src={images.gocard.maskGroup1} alt="mask-group-1"/>
                                    </div>
                                    <div className='mask-group-2'>
                                        <div>
                                            <span>TIẾT KIỆM THỜI GIAN</span>
                                            <p>Với giao diện thân thiện và dễ sử dụng, bạn có thể nhanh chóng tìm kiếm, so sánh, và lựa chọn các sản phẩm tài chính một cách chính xác nhất</p>
                                        </div>
                                        <img src={images.gocard.maskGroup2} alt="mask-group-2"/>
                                    </div>
                                </div>
                                <div className="block">
                                    <div className="label-block">
                                        <h1>15++</h1>
                                        <span>Tổ chức tài chính hợp tác</span>
                                    </div>
                                    <div className="label-block">
                                        <h1>50,000</h1>
                                        <span>Khách hàng mỗi tháng</span>
                                    </div>
                                </div>
                                <div className="label-block-big">
                                    <h1>2,000</h1>
                                    <span>Hồ sơ thành công mỗi tháng</span>
                                </div>
                            </div>
                        </main>
                    </div>
                    <ScrollToTopButton/>
                </Spin>
            </div>
        </>
    );
}

export default React.memo(HomePageView);
