import React, { useEffect, useState } from 'react';
import './SentPhoneOTPPageView.scss';
import phoneOtp from '@assets/icon/phone-otp.svg';
import warningIcon from '@assets/icon/warning-icon.svg';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import { notification } from 'antd';

interface LocationState {
  phone: any;
}

interface RouteParams {
  type: string;
}

const SentPhoneOTPPageView = () => {
  const history = useHistory();

  // Lấy thời gian gửi OTP từ sessionStorage
  const otpSentTime = parseInt(sessionStorage.getItem('otpSentTime') || '0');
  const timeElapsed = Math.floor((Date.now() - otpSentTime) / 1000);

  // Thời gian bắt đầu đếm ngược (120 giây) trừ đi thời gian đã trôi qua
  const initialCounter = 120 - timeElapsed;
  const [counter, setCounter] = useState(initialCounter > 0 ? initialCounter : 0);
  const [canResend, setCanResend] = useState(counter <= 0);
  const location = useLocation<LocationState>();
  const { phone } = location.state || {};
  const [inputPhoneOtp, setIputPhoneOtp] = useState('');
  const [warningIputPhoneOtp, setWarningIputPhoneOtp] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [isOtpValid, setIsOtpValid] = useState(false);

  const { type } = useParams<RouteParams>();

  const lastThreeChars = phone.substring(phone.length - 3);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
    }
  }, [counter]);

  const handleResendClick = async (step: string) => {
    if (canResend) {
      const sessionKey = type === 'card' ? 'cardInfo' : 'loanInfo';

      let storedInfo = sessionStorage.getItem(sessionKey);
      if (storedInfo) {
        let infoObj = JSON.parse(storedInfo);
        sessionStorage.setItem(sessionKey, JSON.stringify(infoObj));
      }
      try {
        const resp = await publicService.applyRegister(step, type, {});
        if (resp.status === 200) {
          api.success({
            message: 'Thành công',
            description: 'Đã gửi lại mã xác nhận'
          });
        }
      } catch (error: any) {
        api.error({
          message: 'Error',
          description: error.response.data,
        });
      }

      // Cập nhật thời gian gửi OTP và đặt lại bộ đếm
      const currentTime = Date.now();
      sessionStorage.setItem('otpSentTime', currentTime.toString());
      setCounter(120);
      setCanResend(false);
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleResendClick('gui-otp-dien-thoai');
  };

  const handleSubmitOtp = async (step: string) => {
    if (inputPhoneOtp === '') {
      setIsOtpValid(false);
      return;
    }
    if (!isOtpValid) {
      return;
    }
    try {
      const resp = await publicService.applyRegister(step, type, { otpRequest: inputPhoneOtp });
      if (resp.status === 200) {
        history.push(`/info/${type}`);
      }
    } catch (error: any) {
      api.error({
        message: 'Lỗi',
        description: error.response.data,
      });
    }
  };

  const handleSubmitOtpValid = (event: React.FormEvent) => {
    event.preventDefault();
    handleSubmitOtp('xac-thuc-otp-dien-thoai');
  };

  const handleInputPhoneOtp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 6) {
      setWarningIputPhoneOtp('Không vượt quá 6 số.');
      return;
    }

    // Kiểm tra nếu giá trị chỉ chứa số và không dài hơn 6 ký tự
    if (/^\d*$/.test(value) && value.length <= 6) {
      setIputPhoneOtp(value);
      setWarningIputPhoneOtp('');

      // Kiểm tra tính hợp lệ của OTP (giả sử mã hợp lệ là chuỗi 6 chữ số)
      if (value.length === 6) {
        setIsOtpValid(true);
      } else {
        setIsOtpValid(false);
      }
    } else {
      setIputPhoneOtp(prevValue => prevValue); // Không thay đổi giá trị nếu không hợp lệ
      setWarningIputPhoneOtp('Không vượt quá 6 số.');
      setIsOtpValid(false); // Không hợp lệ
    }
  };

  const handleBlur = () => {
    if (inputPhoneOtp.length > 6) {
      setWarningIputPhoneOtp('Không vượt quá 6 số.');
    } else {
      setWarningIputPhoneOtp('');
    }
  };

  return (
    <>
      {contextHolder}
      <div className="container-sent-otp">
        <img className='img-phone-otp' src={phoneOtp} alt="phone-otp" />
        <span className='sent-otp'>Đã gửi mã OTP</span>
        <span className='phone-otp'>
          Nhập mã OTP đã được gửi qua số điện thoại đã đăng ký là *********{lastThreeChars}
        </span>
        <div className="otp-input-container">
          <input
            type="password"
            placeholder='Nhập mã OTP ...'
            id='inputPhoneOtp'
            value={inputPhoneOtp}
            onChange={handleInputPhoneOtp}
            onBlur={handleBlur}
            pattern="\d*" // Chỉ cho phép nhập số
          />
          <div className="counter">
            <div className="loading-spinner"></div>
            <span>{`0${Math.floor(counter / 60)}:${counter % 60 < 10 ? '0' : ''}${counter % 60}`}</span>
          </div>
        </div>
        {warningIputPhoneOtp &&
          <div className="warning-message-phoneOtp">
            {warningIputPhoneOtp}
          </div>
        }
        <span className='failed-otp'>Bạn không nhận được mã?
          <a onClick={handleSubmit} className={canResend ? '' : 'disabled-link'}>
            Gửi lại ngay
          </a></span>
        <div className="button-container-otp">
          <button className="btn-continue-otp" onClick={handleSubmitOtpValid}>
            Tiếp tục
          </button>
        </div>
        <div className='warning-text'>
          <img src={warningIcon} alt="warning-icon" />
          <span>
            <b>KHÔNG CHIA SẺ MÃ OTP VỚI BẤT KỲ AI</b><br />
            Ngay cả những người tự xưng là nhân viên Finzoo. Nếu có được mã này, họ có thể truy cập vào tài khoản của bạn
          </span>
        </div>
      </div>
    </>
  );
}

export default React.memo(SentPhoneOTPPageView);
