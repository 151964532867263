import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './CardDetailPageView.scss';
import publicService from "../../../service/PublicService";

interface LocationState {
    cardItem: any
}

const CardDetailPageView = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const { cardItem } = location.state || {};
    console.log(cardItem);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    // Nếu features là một chuỗi JSON, hãy parse nó thành mảng
    let features: string[] = [];
    if (typeof cardItem.features === 'string') {
        try {
            features = JSON.parse(cardItem.features);
        } catch (e) {
            console.error('Error parsing features:', e);
        }
    } else {
        features = cardItem.features || [];
    }

    const handleCreate = async (product: string) => {
        const info = {
            product: product,
        }
        const resp = await publicService.applyRegister('dang-ky', 'credit-card', info);
        if (resp.status === 200) {
            history.push('/success', { cardItem: cardItem })
        }
    }

    return (
        <div className="mx-3 my-3 fs-1 container-card-details">
            <p className="card-detail-header">Chi tiết thẻ</p>
            <div className="border border-3 px-4 py-4 card-wrapper"
                style={{ backgroundColor: '#edfe8c', height: 'fit-content', borderColor: '' }}>
                <p className="fw-bold card-name">{cardItem.name}</p>
                <div className="d-flex flex-row justify-content-around align-item">
                    <img className='img-card' src={cardItem.imageUrl} alt="card" />
                    <div className="ps-2 open-card">
                        <div className="w-100 position-relative">
                            <button
                                className="rounded-pill open-card-btn"
                                onClick={() => handleCreate(cardItem.name)}
                            >Mở thẻ ngay
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container card-detail-grid">
                    <div className="row px-0">
                        {/* {cardItem.map(() => ( */}
                        <div
                            className="col-12 d-grid justify-content-between py-2 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Hạn mức tín dụng</div>
                            <div className="text-start left-col">
                                {cardItem.creditLimitFrom === 0 ? `Hạn mức lên đến ${cardItem.creditLimitTo} triệu` : `${cardItem.creditLimitFrom} triệu - ${cardItem.creditLimitTo} triệu`}
                            </div>
                        </div>
                        <div
                            className="col-12 d-grid justify-content-between py-2 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Phí thường niên</div>
                            <div className="text-start left-col">
                                {cardItem.annualFee} VNĐ
                            </div>
                        </div>
                        <div
                            className="ccol-12 d-grid justify-content-between py-2 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Thu nhập</div>
                            <div className="text-start left-col">
                                Tối thiểu {cardItem.monthlyIncome} triệu
                            </div>
                        </div>
                        <div
                            className="col-12 d-flex justify-content-between py-2 pt-4 px-0 card-detail-info">
                            <div className="text-start right-col">Tính năng nổi bật</div>
                        </div>
                        {/* ))} */}
                    </div>
                </div>
                <div className="container-details-card">
                    <div>
                        {features.map((feature: string, index: number) => (
                            <div
                                key={index}
                                className={`text-start d-grid tag ${index === 0 ? "first-feature" : ""
                                    }`}
                            >
                                <div
                                    className="fw-bold"
                                    dangerouslySetInnerHTML={{ __html: feature }}
                                ></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CardDetailPageView);